import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFilePdf } from 'react-icons/fa';
import meetingsData from '../assets/data/meetings.json';
import documentsData from '../assets/data/documents.json';
import assignmentsData from '../assets/data/assignments.json';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const Dashboard = () => {
  const currentDate = new Date();

  // Filtrar reuniões futuras
  const upcomingMeetings = meetingsData.filter(meeting => new Date(meeting.date) > currentDate).sort((a, b) => new Date(a.date) - new Date(b.date));

  // Filtrar documentos relevantes
  const relevantDocuments = documentsData;

  // Filtrar atribuições pendentes
  const [pendingAssignments, setPendingAssignments] = useState(assignmentsData.filter(assignment => !assignment.completionDate).sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate)));

  // Função para marcar uma tarefa como concluída
  const handleCompletion = (assignmentId) => {
    const updatedAssignments = pendingAssignments.map(assignment => {
      if (assignment.id === assignmentId) {
        assignment.completionDate = new Date().toISOString();
        setTimeout(() => {
          const filteredAssignments = pendingAssignments.filter(assignment => assignment.id !== assignmentId);
          setPendingAssignments(filteredAssignments);
        }, 1000);
      }
      return assignment;
    });

    setPendingAssignments(updatedAssignments);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Your Dashboard</h2>

      <div className="grid grid-cols-2 gap-4">
        {/* Card para Upcoming Meetings */}
        <div className="bg-white rounded-3xl shadow-md p-4">
          <h3 className="text-xl font-bold mb-2">Upcoming Meetings</h3>
          <ul className="list-disc ml-4">
            {upcomingMeetings.map(meeting => (
              <li key={meeting.id} className="mb-2">
                <Link to={`/meetings/${meeting.id}/meeting`} className="text-gray-500 hover:underline">
                  {meeting.title} - {meeting.date} ({meeting.time})
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Mini calendário */}
        <div className="bg-white rounded-3xl shadow-md p-4">
          <h3 className="text-xl font-bold mb-2">Meeting Calendar</h3>
          <Calendar
            className="mx-auto rounded-3xl p-3"
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 py-4">
        {/* Card para Pending Assignments */}
        <div className="bg-white rounded-3xl shadow-md p-4 col-span-2">
          <h3 className="text-xl font-bold mb-2">Pending Assignments</h3>
          <ul className="list-disc ml-4">
            {pendingAssignments.map(assignment => (
              <li key={assignment.id} className="mb-2 flex items-center">
                <input type="checkbox" checked={!!assignment.completionDate} onChange={() => handleCompletion(assignment.id)} className="mr-2" />
                <Link to={`/assignments/${assignment.id}/assignment`} className="text-gray-500 hover:underline">
                  {assignment.name} - Due Date: {assignment.dueDate}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Card para Relevant Documents */}
        <div className="bg-white rounded-3xl shadow-md p-4">
          <h3 className="text-xl font-bold mb-2">Relevant Documents</h3>
          <ul className="list-disc ml-4">
            {relevantDocuments.map(document => (
              <li key={document.id} className="mb-2 flex items-center">
                <FaFilePdf className="text-red-500 mr-2" />
                <a href={document.link} target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:underline">{document.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>


    </div>
  );
};

export default Dashboard;
