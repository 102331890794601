import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { MdGroups } from "react-icons/md";
import { IoCalendar } from "react-icons/io5";
import { FaTasks } from "react-icons/fa";
import { IoDocuments } from "react-icons/io5";
import Dashboard from './components/Dashboard';
import Members from './components/Members';
import Meetings from './components/Meetings';
import Assignments from './components/Assignments';
import Documents from './components/Documents';
import Meeting from './components/Meeting';
import Assignment from './components/Assignment';


export default function App() {
  return (
    <Router>
      <div className="flex h-screen">
        <nav className="bg-black p-4 flex flex-col justify-between h-full fixed left-0 top-0">
          <ul className="flex flex-col">
            <li>
              <p className='text-white text-sm p-2 pb-4'>BOARD-MEETING</p>
              <hr className='border-gray-400 pb-3'/>
            </li>
            <NavItem to="/" icon={<IoMdHome />} text="Home" />
            <NavItem to="/members" icon={<MdGroups />} text="Members" />
            <NavItem to="/meetings" icon={<IoCalendar />} text="Meetings" />
            <NavItem to="/assignments" icon={<FaTasks />} text="Assignments" />
            <NavItem to="/documents" icon={<IoDocuments />} text="Documents" />
          </ul>
        </nav>
        
        <div className="w-full px-4 py-1 ms-40">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/members" element={<Members />} />
            <Route path="/meetings" element={<Meetings />} />
            <Route path="/assignments" element={<Assignments />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/meetings/:meetingId/meeting" element={<Meeting />} />
            <Route path="/assignments/:assignmentId/assignment" element={<Assignment />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

function NavItem({ to, icon, text }) {
  const { pathname } = useLocation();
  const isActive = pathname === to;

  return (
    <li>
      <Link to={to} className={`p-3 my-1 flex items-center rounded-xl ${isActive ? 'bg-white text-black' : 'text-gray-300'}`}>
        {React.cloneElement(icon, { className: `mr-2 ${isActive ? 'bg-white text-black' : 'text-gray-300'}` })}
        {text}
      </Link>
    </li>
  );
}
