import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import assignmentsData from '../assets/data/assignments.json';
import picturesData from '../assets/data/pictures.json';

const Assignment = () => {
  const { assignmentId } = useParams();
  const [assignment, setAssignment] = useState(null);

  useEffect(() => {
    const foundAssignment = assignmentsData.find(assignment => assignment.id === assignmentId);
    setAssignment(foundAssignment);
  }, [assignmentId]);

  const handleCompletion = () => {
    const currentDate = new Date().toISOString();
    setAssignment(prevAssignment => ({
      ...prevAssignment,
      completionDate: currentDate
    }));
  };

  if (!assignment) {
    return <div className="p-4">Assignment not found</div>;
  }

  // Mapeia os dados das imagens por ID
  const picturesMap = {};
  picturesData.forEach(picture => {
    picturesMap[picture.id] = picture.picture;
  });

  return (
    <div className="p-4">
      <div className="bg-white rounded-lg shadow-md p-6">
        <p className="text-lg font-semibold">Name: {assignment.name}</p>
        {assignment.note && (
          <p className="mt-2">Note: {assignment.note}</p>
        )}
        <p>Start Date: {assignment.startDate}</p>
        <p>Due Date: {assignment.dueDate}</p>
        {assignment.completionDate && (
          <p>Completion Date: {assignment.completionDate}</p>
        )}

        <h3 className="text-xl font-bold mt-4 mb-2">Assignees:</h3>
        <div className="flex flex-wrap">
          {assignment.assignees.map(assignee => (
            <div key={assignee.id} className="flex items-center mr-4 mb-4">
              {picturesMap[assignee.id] && (
                <img src={picturesMap[assignee.id]} alt={assignee.displayName} className="w-8 h-8 rounded-full mr-2" />
              )}
              <span>{assignee.displayName} ({assignee.number})</span>
            </div>
          ))}
        </div>

        <div className="mt-4">
          {assignment.completionDate ? (
            <p className="text-green-500 font-semibold">Completed</p>
          ) : (
            <label className="inline-flex items-center">
              <input type="checkbox" checked={!!assignment.completionDate} onChange={handleCompletion} className="mr-2 form-checkbox" />
              <span className="text-blue-500 hover:underline">Mark as Completed</span>
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default Assignment;
