import React, { useState, useRef, useEffect } from 'react';
import assignmentsData from '../assets/data/assignments.json';
import picturesData from '../assets/data/pictures.json';
import { AiOutlineCheckCircle, AiOutlineClockCircle, AiOutlineUnorderedList } from 'react-icons/ai';
import { FaFilter } from "react-icons/fa";

const Assignments = () => {
  const [assignments, setAssignments] = useState(assignmentsData);
  const [filter, setFilter] = useState('all');
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const picturesMap = {};
  picturesData.forEach(picture => {
    picturesMap[picture.id] = picture.picture;
  });

  const handleCompletion = (assignmentId) => {
    const currentDate = new Date().toISOString();
    const updatedAssignments = assignments.map(assignment => {
      if (assignment.id === assignmentId) {
        return { ...assignment, completionDate: currentDate };
      }
      return assignment;
    });
    setAssignments(updatedAssignments);
  };

  const sortedAssignments = [...assignments].sort((a, b) => {
    return Date.parse(a.dueDate) - Date.parse(b.dueDate);
  });

  const filteredAssignments = sortedAssignments.filter(assignment => {
    if (filter === 'pending') {
      return !assignment.completionDate;
    } else if (filter === 'completed') {
      return !!assignment.completionDate;
    }
    return true;
  });

  return (
    <div className="p-4">
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-2xl font-bold">My Assignments</h2>
        <div className="relative inline-block text-left" ref={menuRef}>
          <div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <FaFilter className="-ml-1 mr-2 h-5 w-5 text-black" />
              Filters
            </button>
          </div>
          {/* Menu suspenso */}
          {isOpen && (
            <div
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                <button
                  onClick={() => {
                    setFilter('all');
                    setIsOpen(false);
                  }}
                  className={`${
                    filter === 'all' ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } w-full flex items-center px-4 py-2 text-sm`}
                  role="menuitem"
                >
                  <AiOutlineUnorderedList className="mr-2" /> All
                </button>
                <button
                  onClick={() => {
                    setFilter('pending');
                    setIsOpen(false);
                  }}
                  className={`${
                    filter === 'pending' ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } w-full flex items-center px-4 py-2 text-sm`}
                  role="menuitem"
                >
                  <AiOutlineClockCircle className="mr-2" /> Pending
                </button>
                <button
                  onClick={() => {
                    setFilter('completed');
                    setIsOpen(false);
                  }}
                  className={`${
                    filter === 'completed' ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } w-full flex items-center px-4 py-2 text-sm`}
                  role="menuitem"
                >
                  <AiOutlineCheckCircle className="mr-2" /> Completed
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="grid gap-4">
        {filteredAssignments.map(assignment => (
          <div key={assignment.id} className="bg-white rounded-lg shadow-md p-4">
            <p className="text-lg font-semibold">Name: {assignment.name}</p>
            {assignment.note && (
              <p className="mt-2">Note: {assignment.note}</p>
            )}
            <p>Start Date: {assignment.startDate}</p>
            <p>Due Date: {assignment.dueDate}</p>
            {assignment.completionDate && (
              <p>Completion Date: {assignment.completionDate}</p>
            )}

            <div className="mt-4 items-center">
              <p className="font-semibold mr-2 mb-2">Assignees:</p>
              <ul className="list-disc list-inside">
                {assignment.assignees.map(assignee => (
                  <li key={assignee.id} className="mb-2 flex items-center">
                    {picturesMap[assignee.id] && (
                      <img src={picturesMap[assignee.id]} alt="Assignee Avatar" className="w-6 h-6 rounded-full mr-2" />
                    )}
                    <span>{assignee.displayName}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-4">
              {assignment.completionDate ? (
                <p className="text-green-500 font-semibold">Completed</p>
              ) : (
                <label className="inline-flex items-center">
                  <input type="checkbox" checked={!!assignment.completionDate} onChange={() => handleCompletion(assignment.id)} className="mr-2" />
                  <span className="text-blue-500 hover:underline">Mark as Completed</span>
                </label>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Assignments;
