import React from 'react';
import { Link } from 'react-router-dom';
import meetingsData from '../assets/data/meetings.json';

const Meetings = () => {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Meetings:</h2>

      {meetingsData.map(meeting => (
        <div key={meeting.id} className="bg-white rounded-lg shadow-md p-4 mb-4">
          <h3 className="text-xl font-bold mb-2">{meeting.title}</h3>
          <p>Date: {meeting.date}</p>
          <p>Time: {meeting.time}</p>
          <p>Location: {meeting.locationName}</p>
          <Link to={`/meetings/${meeting.id}/meeting`} className="text-blue-500 hover:underline">View Details</Link>
        </div>
      ))}
    </div>
  );
};

export default Meetings;
