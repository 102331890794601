import React from 'react';
import { FaFileAlt } from 'react-icons/fa';
import documentsData from '../assets/data/documents.json';

const Documents = () => {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Documents from Meeting Items</h2>

      <div className="grid gap-4">
        {documentsData.map(document => (
          <div key={document.id} className="bg-white rounded-lg shadow-md p-4 flex items-center">
            <FaFileAlt className="text-red-500 mr-4 text-3xl" />
            <div>
              <p className="text-lg font-semibold">{document.title}</p>
              <a href={document.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">View Document</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Documents;
