import React, { useState } from 'react';
import { RxAvatar } from "react-icons/rx";
import { IoCloseSharp } from "react-icons/io5";
import membersData from '../assets/data/members.json';
import rolesData from '../assets/data/roles.json';
import picturesData from '../assets/data/pictures.json';

const Members = () => {
  const [selectedMember, setSelectedMember] = useState(null);
  const picture = selectedMember ? picturesData.find(p => p.id === selectedMember.id)?.picture : null;

  const rolesMap = rolesData.reduce((acc, role) => {
    acc[role.id] = role.name;
    return acc;
  }, {});

  const handleMemberClick = (member) => {
    setSelectedMember(member);
  };

  const handleClose = () => {
    setSelectedMember(null);
  };

  return (
    <div className="p-4 flex">
      <div className={`w-${selectedMember ? '2/3' : 'full'} overflow-y-auto`}>
        <h2 className="text-2xl font-bold mb-4">Group Members</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role(s)</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {membersData.map(member => (
              <tr key={member.id} onClick={() => handleMemberClick(member)} style={{ cursor: 'pointer' }}>
                <td className="px-6 py-4 whitespace-nowrap flex items-center">
                  <span className="mr-4">
                    {picturesData.find(p => p.id === member.id) ? (
                      <img src={picturesData.find(p => p.id === member.id).picture} alt={member.displayName} className="w-12 h-12 rounded-full" />
                    ) : (
                      <RxAvatar size={48} className="text-gray-400" />
                    )}
                  </span>
                  <span className="text-lg font-semibold">{member.displayName}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {member.roles && (
                    <span>{member.roles.map((roleId, index) => (
                      <span key={roleId}>
                        {rolesMap[roleId]}{index !== member.roles.length - 1 ? ', ' : ''}
                      </span>
                    ))}</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedMember && (
        <div className="w-1/3 pl-8 fixed right-0 top-0 h-screen">
          <div className="p-4 bg-white rounded-lg shadow-md h-full">
            <button onClick={handleClose} className="mt-4 shadow-md hover:shadow-xl text-gray font-bold p-2 rounded-full">
              <IoCloseSharp />
            </button>
            <div className="flex items-center mb-4 justify-center">
              {picture ? (
                <img src={picture} alt={selectedMember.displayName} className="w-48 h-48 rounded-full mr-4" />
              ) : (
                <RxAvatar size={48} className="text-gray-400 mr-4" />
              )}
            </div>
            <div className='flex mb-4 items-center justify-center'>
              <h2 className="text-2xl font-bold">{selectedMember.displayName}</h2>
              {selectedMember.number && (
                <p className="text-2xl font-bold ms-2">({selectedMember.number})</p>
              )}
            </div>
            {selectedMember.fullName && (
              <p><strong>Full Name: </strong>{selectedMember.fullName}</p>
            )}
            {selectedMember.prosecutor && (
              <p><strong>Prosecutor: </strong>{selectedMember.prosecutor}</p>
            )}
            {selectedMember.roles && (
              <div>
                <strong>Role(s): </strong>
                {selectedMember.roles.map((roleId, index) => (
                  <span key={roleId}>
                    {rolesMap[roleId]}{index !== selectedMember.roles.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </div>
            )}
            {selectedMember.email && (
              <p><strong>Email: </strong>{selectedMember.email}</p>
            )}
            {selectedMember.telephone && (
              <p><strong>Telephone: </strong>{selectedMember.telephone}</p>
            )}
            {selectedMember.address && (
              <div>
                <h3 className="text-lg font-semibold">Address:</h3>
                <p className='ms-4'><strong>Street: </strong>{selectedMember.address.street}</p>
                <p className='ms-4'><strong>Postal Code: </strong>{selectedMember.address.postalCode}</p>
                <p className='ms-4'><strong>Locality: </strong>{selectedMember.address.locality}</p>
                <p className='ms-4'><strong>Country: </strong>{selectedMember.address.country}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Members;
