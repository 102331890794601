import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FaFilePdf } from 'react-icons/fa';
import { RxAvatar } from 'react-icons/rx';
import meetingsData from '../assets/data/meetings.json';
import assignmentsData from '../assets/data/assignments.json';
import documentsData from '../assets/data/documents.json';
import picturesData from '../assets/data/pictures.json';

const Meeting = () => {
  const { meetingId } = useParams();
  const meeting = meetingsData.find(meeting => meeting.id === meetingId);

  if (!meeting) {
    return <div>Meeting not found</div>;
  }

  const getAttendeePhoto = (attendeeId) => {
    const attendee = picturesData.find(attendee => attendee.id === attendeeId);
    return attendee ? attendee.picture : null;
  }

  return (
    <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">{meeting.title}</h2>
        <p className="mb-2"><span className="font-semibold">Description:</span> {meeting.description}</p>
        <p className="mb-2"><span className="font-semibold">Date:</span> {meeting.date}</p>
        <p className="mb-2"><span className="font-semibold">Time:</span> {meeting.time}</p>

        {(meeting.locationName && meeting.onlineLink) && (
            <p className="mb-2">
            <span className="font-semibold">Location:</span> {meeting.locationAddress.street}, {meeting.locationAddress.postalCode} {meeting.locationAddress.locality}, {meeting.locationAddress.country} or <a href={meeting.onlineLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Online Link</a>
            </p>
        )}

        {(!meeting.onlineLink && meeting.locationName) && (
            <p className="mb-2">
            <span className="font-semibold">Location:</span> {meeting.locationAddress.street}, {meeting.locationAddress.postalCode} {meeting.locationAddress.locality}, {meeting.locationAddress.country}
            </p>
        )}

        {(!meeting.locationName && meeting.onlineLink) && (
            <p className="mb-2">
                <a href={meeting.onlineLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Online Link</a>
            </p>
        )}

        <h3 className="text-xl font-bold mt-4 mb-2">Attendees:</h3>
        <ul>
            {meeting.attendees.map(attendee => (
                <li key={attendee.id} className="flex items-center mb-2">
                    {getAttendeePhoto(attendee.id) ? (
                        <img src={getAttendeePhoto(attendee.id)} alt="Attendee Avatar" className="w-6 h-6 rounded-full mr-2" />
                    ) : (
                        <RxAvatar size={20} className="text-gray-400 w-6 h-6 rounded-full mr-2" />
                    )}
                    <span>{attendee.displayName}</span>
                </li>
            ))}
        </ul>

        <h3 className="text-xl font-bold mt-4 mb-2">Agenda:</h3>
        <div className="">
            {meeting.agenda.map(item => (
                <div key={item.order} className="bg-white rounded-lg shadow-md p-4 mb-6">
                    <h3 className="text-md font-bold mb-2">{item.order} - {item.title}</h3>
                    {item.notes && item.notes.filter(note => note.text.trim() !== '').length > 0 && (
                        <ul className='mb-2'>
                            <p className='text-sm text-gray-800'>Note(s):</p>
                            {item.notes.map(note => (
                                note.text.trim() !== '' && (
                                    <li key={note.text} className="text-sm text-gray-500 ms-3">- {note.text}</li>
                                )
                            ))}
                        </ul>
                    )}
                    {item.votes && (
                        <div className='mb-2'>
                            <p className='text-sm text-gray-800'>Votes:</p>
                            <p className="text-sm text-gray-500 ms-3">- In Favor: {item.votes.inFavor}</p>
                            <p className="text-sm text-gray-500 ms-3">- Against: {item.votes.against}</p>
                            <p className="text-sm text-gray-500 ms-3">- Abstensions: {item.votes.abstensions}</p>
                        </div>
                    )}
                    {item.decisions && (
                        <ul className='mb-2'>
                            <p className='text-sm text-gray-800'>Decision(s):</p>
                            {item.decisions.map(decision => (
                                <li key={decision.text} className="text-sm text-gray-500 ms-3">- {decision.text}</li>
                            ))}
                        </ul>
                    )}

                    {item.assignments && (
                        <div className='mb-2'>
                            <p className='text-sm text-gray-800'>Assignment(s):</p>
                            <div className="flex flex-wrap">
                            {item.assignments.map(assignmentId => {
                                const assignment = assignmentsData.find(a => a.id === assignmentId);
                                return assignment ? (
                                <div key={assignment.id} className="bg-gray-100 rounded-lg shadow-md p-4 m-2 w-64">
                                    <p className="text-sm font-semibold">{assignment.name}</p>
                                    <p className="text-xs text-gray-500">Due Date: {assignment.dueDate}</p>
                                    <div className="flex items-center mt-2">
                                    {assignment.assignees.map(assignee => {
                                        const assigneePicture = picturesData.find(pic => pic.id === assignee.id);
                                        return (
                                        <div key={assignee.id} className="flex items-center text-xs text-gray-500 mr-2">
                                            {assigneePicture ? (
                                            <img src={assigneePicture.picture} alt="Assignee Avatar" className="w-6 h-6 rounded-full mr-1" />
                                            ) : (
                                            <RxAvatar size={16} className="text-gray-400 w-6 h-6 rounded-full mr-1" />
                                            )}
                                        </div>
                                        );
                                    })}
                                    </div>
                                    <Link to={`/assignments/${assignment.id}/assignment`} className="text-blue-500 text-xs hover:underline mt-2">View Details</Link>
                                </div>
                                ) : null;
                            })}
                            </div>
                        </div>
                    )}

                    {item.documents && (
                    <div className='mb-2'>
                        <p className='text-sm text-gray-800'>Document(s):</p>
                        {item.documents.map(documentId => {
                        const document = documentsData.find(doc => doc.id === documentId);
                        return (
                            <a key={document.id} href={document.link} target="_blank" rel="noopener noreferrer" className="flex items-center">
                                <FaFilePdf className=" text-red-500" />
                                <p className='text-sm text-gray-500 m-1 hover:underline'>{document.title}</p>
                            </a>
                        );
                        })}
                    </div>
                    )}

                </div>
            ))}
        </div>
    </div>
  );
};

export default Meeting;
